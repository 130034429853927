<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <div class="app-home-table">
      <div class="table-name">
        <div class="title">
          <span>Coa {{ $t('view.list') }}</span>
        </div>
        
        <div class="option">
          <el-button
            type="primary"
            @click="newHandle">
            <template>
              <i class="el-icon-plus"></i>
              <span>New</span>
            </template>
          </el-button>
        </div>
        
      </div>

      <el-row style="padding: 10px;clear:both">
        <el-col :span="5">
          <span>Product Name：</span>
          <el-autocomplete
            clearable
            v-model="params.productName"
            :fetch-suggestions="productNameSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectName"
          ></el-autocomplete>
        </el-col>
        <el-col :span="5">
          <div>
            <span>Item Code：</span>
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="params.itemCode"
              :fetch-suggestions="itemCodeSearch"
              placeholder="Please Input"
              @input="handleSelectCode"
            ></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="4">
          <span>Lot #：</span>
          <el-autocomplete
            clearable
            v-model="params.lot"
            :fetch-suggestions="lotSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectLot"
          ></el-autocomplete>
        </el-col>
        
        <el-col :span="3">
          <div style="margin-right: 20px">
            <el-radio-group
              v-model="dataType"
              size="medium"
              @change="dataTypeChange"
            > 
              <el-radio-button label="COA"></el-radio-button>
              <el-radio-button label="COA Archive"></el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-button
              @click="handleSearch('查询')"
              type="primary"
              style="float: left"
              >Search</el-button>
            <el-button @click="resetSearch('重置')" style="float: left">Reset</el-button>
            <el-upload
              action=""
              :auto-upload="true"
              :on-change="onChange"
              :limit="1"
              style="float: left; margin: 0px 0px 0px 10px"
            >
              <el-button type="success">Upload</el-button>
            </el-upload>
            <el-button @click="showPdf()" type="primary" style="float: left;margin-left:10px;">Upload PDF</el-button>
          </div>
        </el-col>
      </el-row>

      <el-row style="padding: 10px;margin-left:50px;" v-if="selectCoaIdList.length>0 && userInfo.operateStatus && isShowBtn">        
        <el-col :span="2">
          <div>
            <el-button type="primary" @click="addToLibrary">Upload to ERP Library</el-button>
          </div>
        </el-col>
      </el-row>
      </div>

    <div class="sds-list">
      <el-table
        :data="tableData"
        border
        stripe
        v-loading="loading"
        element-loading-text="Data Is Loading..."
        height="70vh"
        style="margin-top: 10px"
      >
        <el-table-column
          type="index"
          :label="this.$t('list.number')"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="itemCode"
          :label="this.$t('list.item_code')"
          width="280"
          v-if="showColumn"
        >
        </el-table-column>
        <el-table-column
          prop="lot"
          :label="this.$t('list.lot')"
          width="280"
        >
        </el-table-column>
        <el-table-column
          prop="productName"
          :label="this.$t('list.product_name')"
          width="280"
        >
        </el-table-column>

        <el-table-column
          prop="verified"
          :label="this.$t('list.verify')"
          align="center"
          width="70"
        >
          <template slot-scope="scope">
            <i
              :class="verify_show(scope.row).icon"
              :style="{ color: verify_show(scope.row).color }"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          prop="tyNumber"
          :label="this.$t('list.ty_number')"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="chinaLot"
          :label="this.$t('list.china_lot')"
          width="140"
          align="center"
          v-if="showColumn"
        >
        </el-table-column>
        <el-table-column
          prop="qty"
          :label="this.$t('list.qty')"
          width="70"
          align="center"
          v-if="showColumn"
        >
        </el-table-column>
        <el-table-column
          prop="shippingMethod"
          :label="this.$t('list.shipping_method')"
          width="140"
          align="center"
          v-if="showColumn"
        >
        </el-table-column>
        <el-table-column
          prop="shipmentDate"
          :label="this.$t('list.shipment_date')"
          width="100"
          align="center"
          v-if="showColumn"
        >
        </el-table-column>
        <el-table-column
          prop="createName"
          :label="this.$t('list.create_by')"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="this.$t('list.create_time')"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ getFormatterTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateName"
          :label="this.$t('list.update_by')"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          :label="this.$t('list.update_time')"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ getFormatterTime(scope.row.updateTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="verifiedName"
          :label="this.$t('list.verified_name')"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="verifiedTime"
          :label="this.$t('list.verified_time')"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ getFormatterTime(scope.row.verifiedTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="this.$t('action.action')"
          fixed="right"
          width="240"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="editRowHandle(scope.row)"
              >{{ labelText("edit") }}</el-button
            >
            <!-- <el-button type="success" plain size="mini" @click="copyRowHandle(scope.row)">{{labelText('copy')}}</el-button>
          <el-button type="danger" plain size="mini" @click="deleteRowHandle(scope.row.id)" v-if="isAdminAuth"
            >{{labelText('delete')}}</el-button
          > -->
          </template>
        </el-table-column>
      </el-table>

      <div class="table-pagition">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageIndex"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import * as mappingUtils from "../spec/config/sdsMapping";
import * as keyConvertUtils from "../spec/config/jsonKeyConverter";
import { apiServer } from "@/network/modules/detail-api";
import HeaderLayout from "@/views/Home/layout/HeaderLayout.vue";
export default {
  components: {
    HeaderLayout,
  },
  computed: {
    isAdminAuth() {
      return !!(
        this.username === "JamesChen" ||
        this.username === "admin" ||
        this.username === "JiaZhang"
      );
    },
    labelText() {
      // 获取标签文本
      return function (text) {
        if (this.$route.query.language === "EN") {
          return keyConvertUtils.convertToTitleCase(text);
        }
        return mappingUtils.getValueByKey(text);
      };
    },
    showColumn() {
      let lang = localStorage.getItem('language') || 'en';
      console.info("lang:", lang);
      return lang === "en" ? true : false;
    },
  },
  data() {
    return {
      loading: false, //数据加载中
      language: "EN",
      username: "JamesChen",
      tableData: [], //表格数据
      dataType:"",
      total: 0, //总数
      suggestionsList: {
        productName: [],
        itemCode: [],
        lot: [],
      },
      searchData:{
        name:"",
        lot:"",
        code:""
      },
      params: {
        //页面参数
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        itemCode: "",
        lot: "",
        productName: "",
        verifiedList: 0,
      },
    };
  },
  created() {
    // this.language = localStorage.getItem("language");
    this.getSuggestionsListData();
  },
  mounted() {
    // 加载数据
    this.params.itemCode = "";
    this.params.productName = "";
    this.params.pageIndex = 1;
    this.getListData();
  },
  methods: {
    handleSearch(){
      this.getListData();
    },
    resetSearch(){
      this.params.pageIndex = 1;
      this.params.productName = "";
      this.params.lot = "";
      this.params.itemCode = "";
      this.getListData();
    },
    productNameSearch(query, callback){
      const results = this.suggestionsSearch(query,this.suggestionsList.productName);
      callback(results);
    },
    itemCodeSearch(query, callback){
      const results = this.suggestionsSearch(query,this.suggestionsList.itemCode);
      callback(results);
    },
    lotSearch(query, callback){
      const results = this.suggestionsSearch(query,this.suggestionsList.lot);
      callback(results);
    },
    suggestionsSearch(queryString,searchList){
      let list = [];
      searchList.forEach((element) => {
        list.push({ value: element });
      });
      let results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      return results;
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    newHandle(){
      let language = localStorage.getItem("language");
      this.$router.push({
        path: "detail",
        query: { type: "coa", language },
      });
    },
    nameSearch(){},
    handleSelectName(){},
    handleSelectLot(){},
    codeSearch(){},
    handleSelectCode(){},
    dataTypeChange(){},
    onChange(){},
    selectCoaIdList(){},
    getJsonValueByKey(json, key) {
      let jsonObj = JSON.parse(json);
      return jsonObj[key];
    },
    tableRowClassName({ rowIndex }) {
      // 行变色
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    getSuggestionsListData(){
      apiServer.getDataByType("coa", "suggestionsList", this.params, (data) => {
        this.suggestionsList.productName = data.map(item => item.productName);
        this.suggestionsList.itemCode = data.map(item => item.itemCode);
        this.suggestionsList.lot = data.map(item => item.lot);
      });
    },
    getListData() {
      //获取数据
      this.loading = true;
      console.info("apiServer:", apiServer);
      apiServer.getDataByType("coa", "getList", this.params, (data) => {
        this.params.pageIndex = data.pageIndex;
        this.params.pageSize = data.pageSize;
        this.total = data.total;
        this.tableData = data.records;
        this.loading = false;
      });
    },
    // 加载 itemCode 数据
    getItemCodeListData(params, callback) {
      apiServer.getDataByType("coa", "itemCodeList", params, (data) => {
        callback(data);
      });
    },
    // verify 列图标显示颜色
    verify_show(val) {
      switch (val.verified) {
        case 1:
          return { icon: "el-icon-success", color: "#67c23a" };
        case 0:
          return { icon: "el-icon-error", color: "#f56c6c" };
        default:
          return { icon: "el-icon-question", color: "#a6a9ad" };
      }
    },
    // 格式化时间
    getFormatterTime(time) {
      if (time) {
        return base.methods.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    // 分页方法
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getListData();
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getListData();
    },
    editRowHandle(row) {
      let language = localStorage.getItem("language");
      this.$router.push({
        path: "detail",
        query: { id: row.id,type:"coa", isCopy: false,language },
      });
    },
    // 复制行
    copyRowHandle(row) {
      this.$confirm("This will Copy the data. Continue?", "Tips", {
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.$router.push({
          path: "detail",
          query: { id: row.id, type:"coa", isCopy: true },
        });
      });
    },
    deleteRowHandle(id) {
      let api = "";
      if (this.language == "CN") {
        this.$api.handleNewSds.deleteNewCnSdsItem(id);
      } else {
        api = this.$api.handleNewSds.deleteNewSdsItem(id);
      }
      api
        .then((data) => {
          console.info(data);
          this.getListData(this.params);
          this.$message({ type: "success", message: "Delete SuccessFully" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/spec-list.css";
.app-home-table{
  margin-top:10px;
  background: white;
}
.table-name{
  width: 100vw;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid gainsboro;
}
.table-name .title{
  float:left;
  margin-left:40px;
}
.table-name .option{
  float:right;
  margin-right: 40px;
}
.table-name span{
  margin-right: 20px;
}
</style>